<template>
  <div class="container">
    <h3 class="primary-title title">{{ $t("announcePage.lastAnnonce") }}</h3>
    <div class="container-card">
      <Card v-for="item in articles" :item="item" :key="item" />
      <InfinityScroll class="loader" @infinite="load" :identifier="refreshLoad">
        <template #spinner>
          <Loader />
        </template>
        <template #complete>
          <div></div>
        </template>
      </InfinityScroll>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import InfinityScroll from "../components/InfinityScroll.vue";
import { useRoute } from "vue-router";
import Loader from "../components/loader.vue";
import Card from "../components/AnnounceCard.vue";

export default {
  components: { InfinityScroll, Loader, Card },
  setup() {
    const store = useStore();
    const route = useRoute();

    const refreshLoad = ref(true);

    const pageSize = 6;
    const currentPage = computed(
      () => store.getters["articles/getArticlesCurrentPage"]
    );

    const newsCount = computed(
      () => store.getters["articles/getArticlesCount"]
    );

    const load = async ($state) => {
      store.dispatch("articles/getArticlesListPagination", {
        cb: () => {
          if (newsCount.value < currentPage.value * pageSize) {
            $state.complete();
          } else {
            $state.loaded();
          }
        },
        pageSize,
        load: 1,
      });
    };
    const refreshData = () => {
      refreshLoad.value = !refreshLoad.value;
      store.commit("articles/flushArticleList");
    };
    refreshData();

    watch(
      () => route.name,
      () => {
        refreshData();
      }
    );

    return {
      refreshLoad,
      load,
      articles: computed(() => store.getters["articles/getArticlesList"]),
    };
  },
};
</script>

<style lang="scss" scoped>
button {
  background: transparent;
  width: max-content;
  color: var(--secondary-font-color);
  line-height: 2;
}
.title {
  margin-top: 3.75rem;
  margin-bottom: 2.9375rem;
}
.container-card {
  font-size: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25rem;
  flex-wrap: wrap;
  .loader {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  // justify-content: space-between;
}

@media screen and (max-width: 992px) {
  .title {
    margin: 1.875rem 0;
  }
}
@media screen and (max-width: 576px) {
  .container-card {
    grid-template-columns: 1fr;
  }
}
</style>
