<template>
  <div
    style="
      width: 100%;
      height: 50vh;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 44px 0;
      grid-column: span 12;
    "
  >
    <div class="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loader",
};
</script>

<style>
</style>