<template>
  <div id="vue3-infinite-loading">
    <slot v-if="state == 'loading'" name="spinner"> Loading . . . </slot>
    <slot v-if="state == 'complete'" name="complete">
      <span> {{ slots?.complete || "No more results!" }} </span>
    </slot>
    <slot
      v-if="state == 'error'"
      name="error"
      :retry="params.emitInfiniteEvent"
    >
      <span class="state-error">
        <span>{{ slots?.error || "Oops something went wrong!" }}</span>
        <button class="retry" @click="params.emitInfiniteEvent">retry</button>
      </span>
    </slot>
  </div>
</template>

<script>
import { onMounted, ref, toRefs, onUnmounted, watch } from "vue";
export default {
  props: {
    top: { type: Boolean, required: false },
    target: { type: [String, Boolean], required: false },
    distance: { type: Number, required: false, default: 0 },
    identifier: { required: false },
    firstLoad: { type: Boolean, required: false, default: true },
    slots: { type: Object, required: false },
  },
  emits: ["infinite"],
  setup(props, context) {
    const state = ref("");
    const { identifier } = toRefs(props);
    let prevHeight;

    const stateHandler = (state) => ({
      loading() {
        state.value = "loading";
      },
      loaded() {
        state.value = "loaded";
      },
      complete() {
        state.value = "complete";
      },
      error() {
        state.value = "error";
      },
    });

    const infiniteEventEmitter = (emit, stateHandler) => {
      return () => {
        stateHandler.loading();
        emit("infinite", stateHandler);
      };
    };

    const isVisible = (el, view) => {
      const rect = el.getBoundingClientRect();
      const viewRect = view.getBoundingClientRect();
      const rectTop = rect.top - viewRect.top;
      return rectTop <= view.clientHeight || !view.clientHeight;
    };
    // generate event handler
    const getEventHandler = (
      el,
      { state, distance, emitInfiniteEvent, top }
    ) => {
      return () => {
        const { scrollTop, scrollHeight, clientHeight } = el;
        // console.log(scrollTop, "scrollTop");

        const validState = state.value == "loaded" || !state.value;
        console.log(state.value, "state.value");
        if (top && Math.ceil(scrollTop) - distance <= 0 && validState) {
          emitInfiniteEvent();
        }
        if (
          !top &&
          Math.ceil(scrollTop) + clientHeight >= scrollHeight - distance &&
          validState
        ) {
          emitInfiniteEvent();
        }
      };
    };
    // start scroll event
    let eventHandler;
    const startScrollEvent = (params) => {
      // console.log(params, "params");
      if (params.target && !document.querySelector(params.target)) {
        return console.error(
          "Vue3 infinite loading: target prop should be a valid css selector"
        );
      }

      const el =
        document.querySelector(params.target) || document.documentElement;
      const target = document.querySelector(params.target) || window;

      const infiniteLoading = document.getElementById("vue3-infinite-loading");
      if (isVisible(infiniteLoading, el) && params.firstLoad) {
        params.emitInfiniteEvent();
      }

      eventHandler = getEventHandler(el, params);
      target.addEventListener("scroll", eventHandler);
    };
    // remove scroll event
    const removeScrollEvent = (params) => {
      const target = document.querySelector(params.target) || window;
      target.removeEventListener("scroll", eventHandler);
    };

    const stateWatcher = (el, prevHeight) =>
      watch(state.value, (newVal) => {
        console.log(newVal);
        if (newVal == "loaded" && top) {
          Promise.resolve().then(() => {
            el.scrollTop = el.scrollHeight - prevHeight;
          });
          prevHeight = el.scrollHeight;
        }
        if (newVal == "complete") {
          removeScrollEvent(params);
        }
      });
    // stateWatcher();
    const identifierWatcher = () =>
      watch(identifier, () => {
        state.value = "";
        removeScrollEvent(params);
        startScrollEvent(params);
      });

    const params = {
      state: state,
      target: props.target,
      distance: props.distance,
      top: props.top,
      firstLoad: props.firstLoad,
      emitInfiniteEvent: infiniteEventEmitter(
        context.emit,
        stateHandler(state)
      ),
    };

    onMounted(() => {
      startScrollEvent(params);
      let el = document.querySelector(props.target) || document.documentElement;
      prevHeight = el.scrollHeight;
      stateWatcher(el, prevHeight);
      if (identifier) {
        identifierWatcher();
      }
    });
    onUnmounted(() => {
      removeScrollEvent(params);
    });

    return { params, state };
  },
};
</script>

<style>
</style>